.popover {
  position: relative;
  width: max-content;
}

.popoverButton {
  display: block;
  background-color: transparent;
  outline: none;
  border: none;
  padding: 10px;
  cursor: pointer;
  position: relative;
  font-size: 16px;
  height: 100%;
}

.popoverButton:hover {
  color: var(--color-primary);
}

.popoverPanel {
  --background-color: #ffffff;
  --shadow-color: 160deg 100% 40%;
  display: none;
  position: absolute;
  z-index: 10;
  right: calc(-15%);
  /* left: 0; */
  padding: 8px;
  min-width: 150px;
  /* box-shadow: 10px 10px 20px -15px grey; */
  /* box-shadow: 2px 2px 2px hsl(var(--shadow-color) / 0.1),
    4px 4px 4px hsl(var(--shadow-color) / 0.1),
    6px 6px 6px hsl(var(--shadow-color) / 0.1); */
  background-color: var(--background-color);
  color: rgb(0, 0, 0);
  filter: drop-shadow(0px 0px 8px hsl(160deg 60% 40% / 0.3));
}

.popoverButton:hover + .popoverPanel {
  display: block;
}

.popover:hover .popoverPanel {
  display: block;
}

.arrowIcon {
  position: absolute;
  right: 25%;
  top: calc(-10%);
  z-index: 999;
}
/* ================================== */

.activeNavLink {
  font-size: 2rem;
  color: green;
  font-weight: var(--font-weight-semi-bold);
}

.headerNavLink {
  display: flex;
  align-items: center;
  /* padding-left: 20px; */
  color: black;
  text-decoration: none;
  height: 80px;
}

.headerNavLink:hover {
  color: #a50000;
}

/* ========================= */

.bottomNavLink {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;
  background-color: white;
  overflow: hidden;
  height: inherit;
  color: inherit;
  width: 20%;
  position: relative;
}

.menuNavLink {
  display: flex;
  align-items: center;
  padding: 8px 0;
  text-decoration: none;
  color: white;
}

.menuNavLinkActive {
  color: orange;
}

/* ================ */

.loadingBar {
  @media (min-width: 550px) {
    position: relative;
    top: 32px;
    left: 36px;
    max-width: calc(100% - 72px);
    overflow: hidden;
  }
}
