/* Popover styling */
.popover_wrapper {
  position: relative;
  margin-top: 1.5rem;
  display: block;
}

.popover_title {
  font-size: 24px;
  line-height: 36px;
  text-decoration: none;
  color: rgb(228, 68, 68);
  text-align: center;
  padding: 15px 0;
}

.popover_content {
  /* opacity: 0; */
  /* visibility: hidden; */
  display: none;
  position: absolute;
  left: -150px;
  transform: translate(0, 10px);
  background-color: #bfbfbf;
  padding: 1.5rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  width: auto;
}

.popover_content:before {
  position: absolute;
  z-index: -1;
  content: '';
  right: calc(50% - 10px);
  top: -8px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #bfbfbf transparent;
  transition-duration: 0.3s;
  transition-property: transform;
}

.popover_wrapper:hover .popover_content {
  z-index: 10;
  /* opacity: 1; */
  /* visibility: visible; */
  display: block;
  transform: translate(0, -20px);
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}

canvas {
  pointer-events: none;
  position: fixed;
  top: 0px;
  background-color: #ffffff00;
  color: #5f0000;
  height: 100vh;
  width: 100vw;
  z-index: 9999999;
}

.confetti-button {
  z-index: 20000000000000000000000000000000000;
  position: absolute;
  top: 0;
  background-color: black;
  color: green;
}
